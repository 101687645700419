.f-item-group {
	@include clearfix;
	margin-top: 3rem;
	margin-bottom: 3rem;
	padding-bottom: 3rem;
	border-bottom: 1px solid color(light);

	&:last-child {
		border-bottom: 0;
		margin-bottom: 0;
	}

	& ~ & {
		margin-top: 0;
	}

	& > & {
		padding-bottom: 1.5rem;
		margin-bottom: 1.5rem;
		border-bottom: 0;

		&:first-of-type {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
			padding-bottom: 0;
		}
	}
}

.f-item-code {
	margin-top: 2rem;
}

.f-item-preview {
	@include clearfix;
}

.f-item-border-bottom {
	border-bottom: 1px solid color(light);
}

.f-item-heading-group {
	@include clearfix;
	margin-bottom: 2rem;
	vertical-align: middle;
}

.f-item-heading {
	margin-top: 0;
	margin-bottom: 0;
	display: inline-block;
	vertical-align: middle;
	line-height: 1;
}


.f-item-controls {
	display: inline-block;
	vertical-align: middle;
	margin-left: 0.5rem;

	.f-control {
		width: 0.875rem;
		height: 0.875rem;
		display: block;
		float: left;

		&:hover {
			use {
				fill: color(normal);
			}
		}

		use {
			fill: color(light);
		}
	}
}

.f-item-hidden {
	display: none;
}

.f-item-notes {
	font-size: 0.875rem;
}
