// menu
.f-menu {
	@include border-box;
	position: fixed;
	top: 0;
	left: 0;
	transform: translate(-$menu-width, 0);
	width: $menu-width;
	height: 100%;
	z-index: 1;
	background-color: color(menu-background);
	overflow-x: hidden;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;

	.f-menu-active & {
		transform: translate(0, 0);
	}

	ul {
		margin-top: 0;
		margin-bottom: 0;
		padding-left: 0;
	}

	> ul {
		margin-top: 0;
		margin-bottom: 1rem;

		> li {
			margin-top: 1rem;
		}
	}

	li {
		list-style-type: none;
		margin-top: 0;
		margin-bottom: 0;
	}

	a {
		display: block;
		padding: 0.33rem 2rem;
		color: color(menu-text) !important;
		text-decoration: none;
		font-size: 0.875rem;
		line-height: 1.5;

		&:hover {
			color: color(menu-text) !important;
			text-decoration: underline;
		}

		&.f-active {
			box-shadow: inset 0.25rem 0 0 0 color(accent);
		}
	}

	.f-menu__heading {
		padding-left: 1.5rem;
		font-weight: 700;
		font-size: 0.875rem;

		&:hover {
			color: color(normal);
		}
	}
}
