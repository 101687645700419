html {
	height: 100%;

	&.f-menu-active {
		overflow: hidden;

		@media (min-width: 60em) {
			overflow: auto;
		}
	}
}

body {
	margin: 0;
	position: relative;

	.f-menu-active & {
		width:100%;
		height: 100%;
		overflow: hidden;

		@media (min-width: 60em) {
			overflow: auto;
			height: auto;
		}
	}
}

.f-container {
	@include clearfix;
	@include border-box;
	position: relative;
	padding: 0 1em;
	z-index: 0;
	min-height: 100vh;

	.f-menu-active & {
		transform: translate($menu-width, 0);

		@media (min-width: 60em) {
			margin-left: $menu-width;
			transform: translate(0, 0);
		}
	}

}
